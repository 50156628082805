import React from 'react'
import Navbar from './components/Navbar/Navbar'
import './App.css'
const App = () => {
  return (
    <div>
      <h1 className='companyName'>CYFROTECH INNOVATIVE SOLUTIONS PVT. LTD.</h1>
      <p className='paragraph'>Coming soon......</p>
    </div>
  )
}

export default App