import React from "react";
const Navbar = () => {
  return (
    <div>
      <div className="portal_name">
        <h3>CyfrotechIS</h3>
      </div>
      
      <div className="button">
      <buttton className="btn">
        click
      </buttton></div>
    </div>
  );
};

export default Navbar;
